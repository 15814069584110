<template>
  <div style="min-height: 362px" class="chats d-flex flex-column justify-content-end">
    <div
      v-for="(msgGrp, index) in formattedChatData.formattedChatLog"
      :key="msgGrp.senderId + String(index)"
      :class="{ 'chat-left': msgGrp.senderId === formattedChatData.contact.id }"
      class="chat"
    >
      <div class="chat-avatar">
        <b-avatar size="36" class="avatar-border-2 box-shadow-1" variant="secondary" />
      </div>
      <div class="chat-body">
        <div v-for="(msgData, key) in msgGrp.messages" :key="key" class="chat-content">
          <audio v-if="msgData.messageType === 'audio' && msgData.msg" :src="msgData.msg" controls></audio>
          <div v-else>
            <div v-html="formattedText(msgData.msg)"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- typing -->
    <div v-if="isTyping" class="chat-left">
      <div class="chat-avatar">
        <b-avatar size="36" class="avatar-border-2 box-shadow-1" variant="secondary" />
      </div>
      <div class="chat-body">
        <div class="chat-content">
          <div class="typing">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    chatData: {
      type: Object,
    },
    profileUserAvatar: {
      type: String,
    },
    isTyping: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formattedText() {
      return (text) =>
        text
          .replace(/\n\n/g, "<br><br>")
          .replace(/\n/g, "<br>")
          .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
    },
    formattedChatData() {
      const contact = {
        id: this.chatData.contact.id,
      };

      let chatLog = [];
      if (this.chatData.chat) {
        chatLog = this.chatData.chat.chat;
      }

      const formattedChatLog = [];
      let chatMessageSenderId = chatLog[0] ? chatLog[0].senderId : undefined;
      let msgGroup = {
        sender: chatMessageSenderId,
        messages: [],
      };

      chatLog.forEach((msg, index) => {
        if (chatMessageSenderId === msg.senderId) {
          msgGroup.messages.push({
            msg: msg.message,
            time: msg?.time,
            messageType: msg?.messageType,
          });
        } else {
          chatMessageSenderId = msg.senderId;
          formattedChatLog.push(msgGroup);
          msgGroup = {
            senderId: msg.senderId,
            messages: [
              {
                msg: msg.message,
                time: msg.time,
                messageType: msg?.messageType,
              },
            ],
          };
        }
        if (index === chatLog.length - 1) formattedChatLog.push(msgGroup);
      });

      return {
        formattedChatLog,
        contact,
      };
    },
  },
};
</script>

<style>
.typing {
  align-items: center;
  display: flex;
  height: 17px;
}
.typing .dot {
  animation: mercuryTypingAnimation 1s infinite ease-in-out;
  background-color: #82868a;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}
.typing .dot:nth-child(1) {
  animation-delay: 100ms;
}
.typing .dot:nth-child(2) {
  animation-delay: 200ms;
}
.typing .dot:nth-child(3) {
  animation-delay: 300ms;
}
.typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: #82868a;
  }
  28% {
    transform: translateY(-7px);
    background-color: #82868a;
  }
  44% {
    transform: translateY(0px);
    background-color: #82868a;
  }
}
</style>
