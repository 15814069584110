<template>
  <div>
    <b-row class="match-height">
      <b-col lg="5">
        <b-card class="chat-widget border" no-body>
          <b-card-header>
            <div class="d-flex align-items-center">
              <b-avatar size="34" class="mr-1" />
              <h5 class="mb-0">Chat Example</h5>
            </div>
            <!--  <feather-icon icon="MoreVerticalIcon" size="18" /> -->
          </b-card-header>

          <section class="chat-app-window">
            <!-- User Chat Area -->
            <vue-perfect-scrollbar ref="refChatLogPS" :settings="perfectScrollbarSettings" class="user-chats scroll-area">
              <chat-log :is-typing="isTyping" :chat-data="activeChat" />
            </vue-perfect-scrollbar>

            <!-- Message Input -->
            <b-form class="chat-app-form py-3" @submit.prevent="sendMessage">
              <b-input-group class="input-group-merge form-send-message mr-1">
                <b-form-input v-model="chatInputMessage" placeholder="Enter your message" />
              </b-input-group>
              <div class="d-flex gap-5">
                <b-button type="submit" :disabled="isRecording" variant="gradient-primary" class="btn-icon rounded-circle">
                  <mdi-icon icon="Send" />
                </b-button>
                <b-button @click="startRecording" :disabled="isRecording" variant="primary" class="btn-icon rounded-circle">
                  <mdi-icon icon="Microphone" />
                </b-button>
                <b-button @click="stopRecording" :disabled="!isRecording" variant="primary" class="btn-icon rounded-circle">
                  <mdi-icon icon="Stop" />
                </b-button>
              </div>
            </b-form>
          </section>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import ChatLog from "./ChatLog.vue";
import RecordRTC from "recordrtc";
import { v4 as uuidv4 } from "uuid";

import { baseURL } from "@/utils/appAxios";

export default {
  components: {
    VuePerfectScrollbar,
    ChatLog,
  },
  data() {
    return {
      thread_id: "",
      isTyping: false,
      recorder: null,
      audioURL: "",
      isRecording: false,
      chatInputMessage: "",
      stream: null,
      perfectScrollbarSettings: { maxScrollbarLength: 150, wheelPropagation: false },
      activeChat: {
        contact: {
          id: 1,
        },
        chat: {
          chat: [],
        },
      },
    };
  },
  methods: {
    scrollToBottomInChatLog() {
      const scrollEl = this.$refs.refChatLogPS.$el || this.$refs.refChatLogPS;
      scrollEl.scrollTop = scrollEl.scrollHeight;
    },
    sendMessage() {
      if (this.chatInputMessage) {
        this.activeChat.chat.chat.push({ senderId: 11, message: this.chatInputMessage });
        this.sendMessageToApi(this.chatInputMessage);
        /* this.$socket.emit("deneme", { message: this.chatInputMessage }); */
        this.chatInputMessage = "";
        this.$nextTick(() => {
          this.scrollToBottomInChatLog();
        });
      }
    },
    getMessage(message) {
      if (message) {
        this.activeChat.chat.chat.push({ senderId: 1, message });
        this.$nextTick(() => {
          this.scrollToBottomInChatLog();
        });
      }
    },
    async sendMessageToApi(content) {
      setTimeout(() => {
        this.isTyping = true;
        this.$nextTick(() => {
          this.scrollToBottomInChatLog();
        });
      }, 100);

      var data = new FormData();
      data.append("content", content);
      data.append("thread_id", this.thread_id);
      const res = await fetch(baseURL + "/openai", {
        method: "POST",
        body: data,
      });
      this.isTyping = false;

      if (!res.body) {
        throw new Error("ReadableStream not yet supported in this browser.");
      }

      const reader = res.body.getReader();
      const decoder = new TextDecoder();
      const messageId = uuidv4();
      this.activeChat.chat.chat.push({ messageId, senderId: 1, message: "" });

      const chatIndex = this.activeChat?.chat?.chat?.findIndex((item) => item?.messageId === messageId);

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }

        const chunk = decoder.decode(value, { stream: true });
        this.activeChat.chat.chat[chatIndex].message += chunk;
        this.$nextTick(() => {
          this.scrollToBottomInChatLog();
        });
      }

      /* setTimeout(() => {
        this.isTyping = true;
      }, 600);
      this.$axios
        .post("/openai", { content, thread_id: this.thread_id })
        .then((result) => {
          this.isTyping = false;
          this.activeChat.chat.chat.push({ senderId: 1, message: result?.data?.message });
          this.$nextTick(() => {
            this.scrollToBottomInChatLog();
          });
        })
        .catch((err) => {
          this.isTyping = true;
          console.log(err);
        }); */
    },
    requestMicrophonePermission() {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(() => {
          this.startRecording();
        })
        .catch((err) => {
          console.error("Microphone access denied:", err);
        });
    },
    async startRecording() {
      navigator.permissions.query({ name: "microphone" }).then(async (result) => {
        if (result.state === "granted") {
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          this.stream = stream;
          this.recorder = new RecordRTC(stream, { type: "audio" });
          this.recorder.startRecording();
          this.isRecording = true;
        } else {
          this.requestMicrophonePermission();
        }
      });
    },
    stopRecording() {
      this.$nextTick(() => {
        this.recorder.stopRecording((blobURL) => {
          this.isRecording = false;
          let blob = this.recorder.getBlob();
          this.$socket.emit("deneme", { blob });
          this.stream.getTracks().forEach((track) => track.stop());
          this.activeChat.chat.chat.push({ message: blobURL, messageType: "audio", senderId: 11 });
        });
      });
    },
  },
  async mounted() {
    this.$socket.on("deneme-return", (data) => {
      // Create a Blob from the array buffer
      const blob = new Blob([data?.buffer], { type: "audio/mp3" });

      // Generate a Blob URL
      const blobUrl = URL.createObjectURL(blob);

      this.activeChat.chat.chat.push({ message: blobUrl, messageType: "audio", senderId: 1 });
    });

    this.$axios
      .get("/openai/create-thread")
      .then((result) => {
        this.thread_id = result?.data?.thread_id;
      })
      .catch((err) => {
        console.log(err);
      });

    /*  const response = await fetch("http://localhost:2223/deneme");

    if (!response.body) {
      throw new Error("ReadableStream not yet supported in this browser.");
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    const messageId = uuidv4();
    this.activeChat.chat.chat.push({ messageId, senderId: 1, message: "" });

    const chatIndex = this.activeChat?.chat?.chat?.findIndex((item) => item?.messageId === messageId);

    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        break;
      }

      const chunk = decoder.decode(value, { stream: true });
      this.activeChat.chat.chat[chatIndex].message += chunk;
    } */
  },
};
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
